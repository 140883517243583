<template>
  <name-with-avatar
    :name="model.name"
    :path="model.preview_image"
    v-if="model"
  />
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Category } from "@planetadeleste/vue-mc-shopaholic";
import { AppModule } from "@/store/app";
import NameWithAvatar from "@/components/common/NameWithAvatar.vue";
import { findDeep } from "deepdash-es/standalone";

@Component({
  components: { NameWithAvatar },
})
export default class CategoryViewName extends Vue {
  @Prop(Number) readonly id!: number;
  @Prop(Object) readonly item!: Category;

  getLoadedCategory(id: number) {
    return AppModule.categories.find({ id });
  }

  get categories() {
    return AppModule.categories;
  }

  get model() {
    if (this.id) {
      const obDeepEntry = findDeep(
        this.categories.getModelList(),
        (obCategory) => {
          return obCategory && obCategory.id == this.id;
        },
        { childrenPath: ["children"] }
      );

      return obDeepEntry?.value;
    }

    return this.item;
  }
}
</script>
